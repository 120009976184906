import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import {
  useGetSPQualDetailsQuery,
  useUpdateSPQualMutation,
} from '../slices/spqualsApiSlice.js';
import ModalSPQ from '../components/ModalSPQnotes.jsx';

import { useGetClinicalSitesNPQuery } from '../slices/clinicalSitesApiSlice';

import { useGetUsersQuery } from '../slices/usersApiSlice';

const SPQualEditScreen = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); // This tells the browser to go back to the previous page
  };

  const { pageNumber } = useParams();
  const [show, setShow] = useState(false);
  const {
    data: clinicalsitesdata,
    isLoading: isLoadingCS,
    error: errorCS,
    refetch: refetchCS,
  } = useGetClinicalSitesNPQuery();
  const {
    data: users,
    refetch: refetchu,
    isLoading: isLoadingu,
    error: erroru,
  } = useGetUsersQuery();

  const { id: SPQualId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  const [clinicalSiteName, setClinicalSiteName] = useState('');
  const [comments, setComments] = useState('');
  const [description, setDescription] = useState('');
  const [userID, setUserID] = useState(userInfo._id);
  const [consultantName, setConsultantName] = useState(userInfo.name);

  const [activeYorN, setActiveYorN] = useState('');
  const [refNumber, setRefNumber] = useState('');
  const [profBody, setProfBody] = useState('');

  const [reviewDate, setReviewDate] = useState(new Date());
  const [effectiveDate, setEffectiveDate] = useState(new Date());

  const {
    data: spqual,
    isLoading,
    refetch,
    error,
  } = useGetSPQualDetailsQuery(SPQualId);

  //#f5f5dc
  const dropdownStyle = {
    backgroundColor: '#F5F5F5', //#F0E68C'   // '#f0f8ff'                 //'lightyellow',
    // Add other styles as needed
  };
  const textStyle = {
    backgroundColor: '#f5f5dc',
    // Add other styles as needed
  };

  const [updateSPQual, { isLoading: loadingUpdate }] =
    useUpdateSPQualMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateSPQual({
        SPQualId,
        userID,
        consultantName,
        comments,
        activeYorN,
        description,
        profBody,
        refNumber,
        reviewDate,
        effectiveDate,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Specialised Expertise updated');
      refetch();
      navigate('/SPQuallist');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  /*
    useEffect(() => {
        // Set an initial date when the component mounts
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        setReviewDate(formattedDate);
        

    }, []); // The empty dependency array ensures this effect runs only once when the component mounts

*/

  useEffect(() => {
    if (spqual) {
      const reviewDate1 = new Date(spqual.reviewDate);
      //const reviewDate2 = reviewDate1.toISOString().slice(0, 10);
      //const reviewDate2 = reviewDate1 ? reviewDate1.toISOString().slice(0, 10) : '';
      //const reviewDate2 = (reviewDate1 ?? '').toISOString().slice(0, 10);

      let reviewDate2 = '';

      if (
        reviewDate1 &&
        reviewDate1 instanceof Date &&
        !isNaN(reviewDate1.getTime())
      ) {
        reviewDate2 = reviewDate1.toISOString().slice(0, 10);
        // Further processing with reviewDate2
      } else {
        reviewDate2 = '';
        //console.error('reviewDate1 is not a valid Date object or is null/undefined');
        // Handle the case where reviewDate1 is not a valid date or is null/undefined
      }

      const effectiveDate1 = new Date(spqual.effectiveDate);
      //const effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
      //const effectiveDate2 = (effectiveDate1 ?? '').toISOString().slice(0, 10);

      let effectiveDate2 = '';

      if (
        effectiveDate1 &&
        effectiveDate1 instanceof Date &&
        !isNaN(effectiveDate1.getTime())
      ) {
        effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
        // Further processing with reviewDate2
      } else {
        effectiveDate2 = '';
        //console.error('reviewDate1 is not a valid Date object or is null/undefined');
        // Handle the case where reviewDate1 is not a valid date or is null/undefined
      }

      setDescription(spqual.description);
      setComments(spqual.comments);
      setActiveYorN(spqual.activeYorN);
      setRefNumber(spqual.refNumber);
      setProfBody(spqual.profBody);
      setReviewDate(reviewDate2);
      setEffectiveDate(effectiveDate2);
    }
  }, [spqual]);

  // Function to handle modal close without action
  const handleClose = () => {
    setShow(false);
  };
  // Function to handle modal confirmation
  const handleConfirm = () => {
    setShow(false);
    refetch();
    // navigate(`/worklist/${worklistId}`);
  };

  return (
    <>
      <Button variant='primary' onClick={goBack}>
        Go Back
      </Button>
      <FormContainer>
        <h1>Edit Speclist Qualifications</h1>
        {loadingUpdate && <Loader />}
        {isLoadingCS && <Loader />}
        <ModalSPQ
          show={show}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#F0F8FF',
              }}
            >
              <Row>
                <Col>
                  <Form.Group controlId='profBody'>
                    <Form.Label>Prof Body</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Prof Body'
                      value={profBody}
                      onChange={(e) => setProfBody(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='RefNumber'>
                    <Form.Label>Ref Number</Form.Label>
                    <Form.Control
                      type='string'
                      placeholder='Ref Number'
                      value={refNumber}
                      onChange={(e) => setRefNumber(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId='Description'>
                <Form.Label>Description</Form.Label>

                <Form.Control
                  type='text'
                  placeholder='Description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group controlId='effectiveDate'>
                    <Form.Label>Effective Date </Form.Label>
                    <Form.Control
                      type='date'
                      placeholder='Effective Date '
                      value={effectiveDate} // {effectiveDate.toISOString().split('T')[0]}  //{effectiveDate}
                      onChange={(e) => setEffectiveDate(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='reviewDate'>
                    <Form.Label>Review Date </Form.Label>

                    <Form.Control
                      type='date'
                      value={reviewDate}
                      onChange={(e) => setReviewDate(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId='comments'>
                <Form.Label>Comments</Form.Label>

                <Form.Control
                  type='text'
                  placeholder='Comments'
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>
              {/*
              <Form.Group controlId='activeYorN'>
                <Form.Label>Active Yes or No</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Active YorN'
                  value={activeYorN}
                  onChange={(e) => setActiveYorN(e.target.value)}
                  style={dropdownStyle}
                >
                  <option value={activeYorN}>{activeYorN}</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </Form.Control>
              </Form.Group>
              */}
            </div>

            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default SPQualEditScreen;
