import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Paginate from '../../components/PaginateSpecialitys';
import {
  useGetCostcentresQuery,
  useGetCostcentresNoPageQuery,
  useGetCostcentresDetailsQuery,
  useCreateCostcentresMutation,
  useUpdateCostcentresMutation,
  useDeleteCostcentresMutation,
} from '../../slices/adminSpecialityApiSlice';

import { toast } from 'react-toastify';

const SpecialityListScreen = () => {
  const { pageNumber } = useParams();

  const { data, isLoading, error, refetch } = useGetCostcentresQuery({
    pageNumber,
  });

  const [deleteSpeciality, { isLoading: loadingDelete }] =
    useDeleteCostcentresMutation();

  const deleteHandler = async (id) => {
    if (window.confirm(`Are you sure  ${id}`)) {
      try {
        await deleteSpeciality(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [createSpeciality, { isLoading: loadingCreate }] =
    useCreateCostcentresMutation();

  const createSpecialityHandler = async () => {
    if (window.confirm('Are you sure you want to create a new Speciality?')) {
      try {
        await createSpeciality();
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  //console.log('data', data);

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Speciality</h1>
        </Col>
        <Col className='text-end'>
          <Button className='my-3' onClick={createSpecialityHandler}>
            <FaPlus /> Create A New Speciality
          </Button>
        </Col>
      </Row>

      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.data.message}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Speciality</th>

                <th>Active Y or N</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.costcentres.map((speciality) => (
                <tr key={speciality._id}>
                  <td>{speciality._id}</td>
                  <td>{speciality.speciality}</td>

                  <td>{speciality.activeYorN}</td>

                  <td>
                    <LinkContainer
                      to={`/admin/speciality/${speciality._id}/edit`}
                    >
                      <Button variant='outline-info' className='btn-sm mx-2'>
                        <FaEdit /> Edit Item
                      </Button>
                    </LinkContainer>

                    <Button
                      variant='warning'
                      className='btn-sm'
                      onClick={() => deleteHandler(speciality._id)}
                    >
                      <FaTrash
                        className='text-end'
                        style={{ color: 'white' }}
                      />{' '}
                      Delete
                    </Button>
                  </td>

                  {/*
                  

                                    <td>
                                        <LinkContainer to={`/admin/speciality/${speciality._id}/edit`}>
                                            <Button variant='light' className='btn-sm mx-2'>
                                                <FaEdit />
                                            </Button>
                                        </LinkContainer>
                                        <Button
                                            variant='danger'
                                            className='btn-sm'
                                            onClick={() => deleteHandler(speciality._id)}
                                        >
                                            <FaTrash style={{ color: 'white' }} />
                                        </Button>
                                    </td>

                            */}
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={data.pages} page={data.page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default SpecialityListScreen;
