import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
//import { useParams } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/PaginateCommitments';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  useGetMySPQualsQuery,
  useDeleteSPQualMutation,
  useCreateSPQualMutation,
} from '../slices/spqualsApiSlice.js';
import { toast } from 'react-toastify';

const SPQualListScreen = () => {
  //const { pageNumber } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const { myUserId } = userInfo._id;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // This tells the browser to go back to the previous page
  };
  //console.log('userInfo._id', userInfo._id);

  const { data, isLoading, error, refetch } = useGetMySPQualsQuery({
    myUserId,
  });

  const [deleteSPQual, { isLoading: loadingDelete }] =
    useDeleteSPQualMutation();

  const deleteHandler = async (id) => {
    if (window.confirm(`Are you sure  ${id}`)) {
      try {
        await deleteSPQual(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [createSPQual, { isLoading: loadingCreate }] =
    useCreateSPQualMutation();

  const createSPQualHandler = async () => {
    if (window.confirm('Are you sure you want to create a new entry?')) {
      try {
        await createSPQual();
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  // className='table-sm'th {

  return (
    <>
      <Button variant='primary' onClick={goBack}>
        Go Back
      </Button>

      <Row className='align-items-center'>
        <Col>
          <h3>Specialised Expertise - Advanced Competencies</h3>
        </Col>
        <Col className='text-end'>
          <Button className='my-3' onClick={createSPQualHandler}>
            <FaPlus /> Create a new entry
          </Button>
        </Col>
      </Row>

      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.data.message}</Message>
      ) : (
        <>
          <div className='table-responsive{-sm|-md|-lg|-xl|-xxl}'>
            <Table
              striped
              bordered
              hover
              responsive
              className='table-sm'
              position='sticky'
              top='0'
            >
              <thead>
                <tr>
                  <th>Consultant Name</th>
                  <th>Decription</th>
                  <th>Professional Body</th>
                  <th>Ref Number</th>
                  <th>Effective Date</th>
                  <th>Review Date</th>

                  <th> Comments</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.spquals.map((spqual) => (
                  // Adjust 'en-GB' based on your locale clinicalSCommitment.reviewDate

                  <tr key={spqual._id}>
                    <td>{spqual.consultantName}</td>
                    <td>{spqual.description}</td>

                    <td>{spqual.profBody}</td>
                    <td>{spqual.refNumber}</td>

                    <td>
                      {isNaN(Date.parse(spqual.effectiveDate))
                        ? ''
                        : new Date(spqual.effectiveDate).toLocaleDateString(
                            'en-GB'
                          )}
                    </td>
                    <td>
                      {isNaN(Date.parse(spqual.reviewDate))
                        ? ''
                        : new Date(spqual.reviewDate).toLocaleDateString(
                            'en-GB'
                          )}
                    </td>

                    <td>{spqual.comments}</td>

                    <td>
                      <LinkContainer to={`/spqualList/${spqual._id}/edit`}>
                        <Button variant='outline-info' className='btn-sm mx-2'>
                          <FaEdit /> Edit Item
                        </Button>
                      </LinkContainer>

                      {/*
                      <Button
                        variant='warning'
                        className='btn-sm'
                        onClick={() => deleteHandler(spqual._id)}
                      >
                        <FaTrash
                          className='text-end'
                          style={{ color: 'white' }}
                        />{' '}
                        Delete
                      </Button>
*/}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Paginate pages={data.pages} page={data.page} isAdmin={false} />
        </>
      )}
    </>
  );
};

export default SPQualListScreen;
