import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col, Container } from 'react-bootstrap';

import { FaEdit, FaPlus, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import '../../utils/formContainer.css';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Paginate from '../../components/PaginateCommitments';
import {
  useGetRadiationSafetyQuery,
  useDeleteRadiationSafetyMutation,
  useCreateRadiationSafetyMutation,
} from '../../slices/radiationSafetyApiSlice';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import {
  DataGrid,
  GridColumnMenuHideItem,
  GridToolbar,
  gridClasses,
} from '@mui/x-data-grid';
import {
  grey,
  yellow,
  common,
  orange,
  lightGreen,
  red,
} from '@mui/material/colors';
import { darken, lighten, styled } from '@mui/material/styles';
import { width } from '@mui/system';
//import { hidden } from 'colors/index.js';

//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

//__________________________________________________________

const RadiationSafetyListScreen = () => {
  const { pageNumber } = useParams();

  const {
    data: radiationSafety,
    isLoading,
    error,
    refetch,
  } = useGetRadiationSafetyQuery({
    pageNumber,
  });

  const [deleteRadiationSafety, { isLoading: loadingDelete }] =
    useDeleteRadiationSafetyMutation();

  const deleteHandler = async (id) => {
    if (window.confirm(`Are you sure  ${id}`)) {
      try {
        await deleteRadiationSafety(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [createRadiationSafety, { isLoading: loadingCreate }] =
    useCreateRadiationSafetyMutation();

  const createRadiationSafetyHandler = async () => {
    if (
      window.confirm(
        'Are you sure you want to create a new radiation safety record?'
      )
    ) {
      try {
        await createRadiationSafety();
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  //_________________________________________________________________

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  //___________for the grid

  useEffect(() => {
    if (radiationSafety) {
      refetch();
      //console.log('data', radiationSafety);
      //console.log('users 1', users[0].user);

      const formattedRows = radiationSafety.radiationSafety
        .slice()
        .sort((a, b) => {
          // Compare by day
          const clinicalSiteName = a.clinicalSiteName.localeCompare(
            b.clinicalSiteName
          );

          if (clinicalSiteName !== 0) {
            return clinicalSiteName;
          }
          /*
      // Compare by period
      const periodComparison = a.period.localeCompare(b.period);

      if (periodComparison !== 0) {
        return periodComparison;
      }
        

      // Create Date objects for start times
      const startTimeA = new Date(0, 0, 0, a.startHour, a.startMin);
      const startTimeB = new Date(0, 0, 0, b.startHour, b.startMin);

      // Compare by start time
      const startTimeComparison = startTimeA - startTimeB;

      if (startTimeComparison !== 0) {
        return startTimeComparison;
      }

      // If start times are the same, compare by activity
      return a.activity.localeCompare(b.activity);
      */
        })
        .map((radiationSafety) => ({
          id: radiationSafety._id.toString(), // Ensure each item has a unique ID and convert ObjectId to string
          clinicalSiteName: radiationSafety.clinicalSiteName,
          consultantName: radiationSafety.consultantName, // Access the nested user object
          category: radiationSafety.category,
          wholeBody: radiationSafety.wholeBody,
          skin: radiationSafety.skin,
          lensEyes: radiationSafety.lensEyes,
          neutron: radiationSafety.neutron,
          committedEffectiveDose: radiationSafety.committedEffectiveDose,

          radionuclide: radiationSafety.radionuclide,
          activityOfRadionuclide: radiationSafety.activityOfRadionuclide,
          totalEffectiveDose: radiationSafety.totalEffectiveDose,

          effectiveDate: isNaN(Date.parse(radiationSafety.effectiveDate))
            ? ''
            : new Date(radiationSafety.effectiveDate).toLocaleDateString(
                'en-GB'
              ),

          reviewDate: isNaN(Date.parse(radiationSafety.reviewDate))
            ? ''
            : new Date(radiationSafety.reviewDate).toLocaleDateString('en-GB'),

          comments: radiationSafety.comments,
          activeYorN: radiationSafety.activeYorN,
        }));

      setLoading(false);

      setRows(formattedRows);
      //get window size
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [radiationSafety]);

  const navigate = useNavigate();

  const handleEdit = (id) => {
    //console.log(`Edit item with ID: ${id}`);
    //console.log(`Edit item with ID: ${uKey}`);

    navigate(`/admin/radiationSafety/${id}/edit`);
  };

  const handleDelete = (id) => {
    //console.log(`Delete item with ID: ${id}`);
    // Add your delete handling logic here
  };

  const columns = [
    //{ field: 'id', headerName: 'ID', hide: true, width: 90 },
    { field: 'clinicalSiteName', headerName: 'linical Site Name', width: 150 },
    {
      field: 'consultantName',
      headerName: 'Name',
      width: 100,
    },

    { field: 'category', headerName: 'Category', width: 100 },

    { field: 'wholeBody', headerName: 'WholeBody', width: 50 },
    { field: 'skin', headerName: 'Skin', width: 50 },
    { field: 'lensEyes', headerName: 'Lens Eyes', width: 50 },
    { field: 'neutron', headerName: 'Neutron', width: 50 },
    {
      field: 'committedEffectiveDose',
      headerName: 'Committed Effective Dose',
      width: 100,
    },
    { field: 'radionuclide', headerName: 'Radionuclide', width: 50 },
    {
      field: 'activityOfRadionuclide',
      headerName: 'Activity Of Radionuclide',
      width: 50,
    },
    {
      field: 'totalEffectiveDose',
      headerName: 'Total Effective Dose',
      width: 50,
    },

    { field: 'effectiveDate', headerName: 'Effective Date', width: 100 },

    { field: 'reviewDate', headerName: 'Review Date', width: 100 },
    { field: 'comments', headerName: 'Comments', width: 100 },
    //{ field: 'activeYorN', headerName: 'active YorN', width: 100 },

    {
      field: 'activeYorN',
      headerName: 'Active',
      width: 50,
      renderCell: (params) => {
        const value = params.value ? params.value.trim().toLowerCase() : '';
        //console.log(`Value for activeYorN: ${value}`); // Debugging line
        return value === 'yes' ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        );
      },
    },

    /*
    {
      field: 'isManager',
      headerName: 'manager',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },

    {
      field: 'isApproved',
      headerName: 'Approved',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },

    */

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size='small'
            variant='outlined'
            color='info'
            //onClick={() => handleEdit(params.id, params.row.UKey))}
            onClick={() => handleEdit(params.id)}
          >
            <FaEdit /> Edit
          </Button>
          <Button
            size='small'
            variant='contained'
            color='warning'
            onClick={() => handleDelete(params.id)}
          >
            <FaTrash style={{ color: 'brown' }} /> Delete
          </Button>
        </Box>
      ),
    },
  ];

  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      //console.log(action);
      console.dir(state);
    },
  };

  //valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,

  /* // Otherwise filter will be applied on fields such as the hidden column id
const columns = React.useMemo(
() => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
[data.columns],
); */

  //<MUIDataTable title={'ACME Employee list'} data={data} columns={columns} options={options} />

  //options={options}

  //
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 1,
      bottom: params.isLastVisible ? 0 : 1,
    };
  }, []);
  //
  //
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  //
  //

  const StyledDataGrid = styled(DataGrid)`
    & .${gridClasses.row}.lightGreenRow {
      background-color: ${lightGreen[100]};
    }
    & .${gridClasses.row}.yellowRow {
      background-color: ${yellow[100]};
    }
    & .${gridClasses.row}.redRow {
      background-color: ${red[100]};
    }

    & .${gridClasses.row}.orangeRow {
      background-color: ${orange[100]};
    }

    &
      .${gridClasses.row}:nth-child(odd):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${grey[200]};
    }

    &
      .${gridClasses.row}:nth-child(even):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${common.white}; // Assuming 'common.white' was intended
    }
  `;

  //
  // Go Back - the windowWidth is {windowWidth}

  //_______________________

  //className='table-sm'
  /*
<th>WorklistID</th>
<th>ID</th>
<td>{user.worklistId}</td>
<td>{user.user._id}</td>

*/
  //_______________________________________________________________

  return (
    <>
      <Container fluid className='form-container'>
        <Row className='justify-content-md-center'>
          {windowWidth < 900 ? (
            <Col md={12}>
              <Link to='/' className='btn btn-light mb-4'>
                Go Back
              </Link>
              <Row className='align-items-center'>
                <Col>
                  <h1>Radiation Safety Records</h1>
                </Col>
                <Col className='text-end'>
                  <Button
                    className='my-3'
                    onClick={createRadiationSafetyHandler}
                  >
                    <FaPlus /> Create Radiation Safety Records
                  </Button>
                </Col>
              </Row>

              {loadingCreate || loadingDelete || isLoading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error.data.message}</Message>
              ) : (
                <div style={{ overflowX: 'auto' }}>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className='custom-table'
                  >
                    <thead>
                      <tr>
                        <th>Clinical Site Name</th>
                        <th>Consultant Name</th>

                        <th>category</th>
                        <th>WholeBody</th>
                        <th>Skin</th>
                        <th>Lens Eyes</th>
                        <th>Neutron</th>
                        <th>Committed Effective Dose</th>
                        <th>Radionuclide</th>
                        <th>Activity Of Radionuclide</th>
                        <th>Total Effective Dose mSv</th>

                        <th>Effective Date</th>
                        <th>Review Date</th>
                        <th> Comments</th>
                        <th>Active Y or N</th>

                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {radiationSafety.radiationSafety.map(
                        (radiationSafety) => (
                          // Adjust 'en-GB' based on your locale clinicalSCommitment.reviewDate

                          <tr key={radiationSafety._id}>
                            <td>{radiationSafety.clinicalSiteName}</td>

                            <td>{radiationSafety.consultantName}</td>

                            <td>{radiationSafety.category}</td>
                            <td>{radiationSafety.wholeBody}</td>
                            <td>{radiationSafety.skin}</td>
                            <td>{radiationSafety.lensEyes}</td>
                            <td>{radiationSafety.neutron}</td>
                            <td>{radiationSafety.committedEffectiveDose}</td>
                            <td>{radiationSafety.radionuclide}</td>
                            <td>{radiationSafety.activityOfRadionuclide}</td>
                            <td>{radiationSafety.totalEffectiveDose}</td>

                            <td>
                              {isNaN(Date.parse(radiationSafety.effectiveDate))
                                ? ''
                                : new Date(
                                    radiationSafety.effectiveDate
                                  ).toLocaleDateString('en-GB')}
                            </td>
                            <td>
                              {isNaN(Date.parse(radiationSafety.reviewDate))
                                ? ''
                                : new Date(
                                    radiationSafety.reviewDate
                                  ).toLocaleDateString('en-GB')}
                            </td>

                            <td>{radiationSafety.comments}</td>
                            <td>{radiationSafety.activeYorN}</td>

                            <td>
                              <LinkContainer
                                to={`/admin/radiationSafety/${radiationSafety._id}/edit`}
                              >
                                <Button
                                  variant='outline-info'
                                  className='btn-sm mx-2'
                                >
                                  <FaEdit /> Edit Item
                                </Button>
                              </LinkContainer>

                              <Button
                                variant='warning'
                                className='btn-sm'
                                onClick={() =>
                                  deleteHandler(radiationSafety._id)
                                }
                              >
                                <FaTrash
                                  className='text-end'
                                  style={{ color: 'white' }}
                                />{' '}
                                Delete
                              </Button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <Paginate
                    pages={radiationSafety.pages}
                    page={radiationSafety.page}
                    isAdmin={true}
                  />
                </div>
              )}
            </Col>
          ) : (
            <Col md={12} className='justify-content-md-center'>
              <h1>Approval Required</h1>

              {loadingCreate || loadingDelete || isLoading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>
                  {error?.data?.message || error.error}
                </Message>
              ) : (
                <Row className='w-100 mt-3'>
                  <Box sx={{ height: 800, width: '100%' }}>
                    <StyledDataGrid
                      density='compact'
                      rows={rows}
                      columns={columns}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      loading={loading}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 50,
                          },
                        },
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns name , the other columns will remain visible
                            user: false,
                          },
                        },
                      }}
                      pageSizeOptions={[50]}
                      checkboxSelection
                      disableRowSelectionOnClick
                      /*getRowClassName={(params) => {
                    if (params.row.activity.startsWith('Lunch Break')) {
                      return 'lightGreenRow';
                    } else if (params.row.period.startsWith('For Noting')) {
                      return 'orangeRow';
                    } else if (params.row.activity.startsWith('zPlease')) {
                      return 'redRow';
                    }
                    return '';
                  }}
                    */
                      getRowSpacing={getRowSpacing}
                    />
                  </Box>
                </Row>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default RadiationSafetyListScreen;
