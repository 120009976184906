import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/bootstrap.custom.css';
import './assets/styles/index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
//import AuthMonitor from './utils/authMonitorold';
//import InactivityTracker from './utils/inactivityTracker';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ResetScreen from './screens/ResetScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import ProfileScreen from './screens/ProfileScreen';
import WorklistEditActivityScreen from './screens/WorklistEditActivityScreen';
import WorklistScreen from './screens/WorklistScreen';
import WorklistEditItemScreen from './screens/WorklistEditItemScreen';

import WorklistEditItemScreenM from './screens/manager/WorklistEditItemScreenM';
import WorklistEditScreen from './screens/WorklistEditScreen';


import OrderListScreen from './screens/admin/OrderListScreen';
import ProductListScreen from './screens/admin/ProductListScreen';
import ProductEditScreen from './screens/admin/ProductEditScreen';
import UserListScreen from './screens/admin/UserListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import store from './store';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import ActivityListScreen from './screens/admin/ActivityListScreen';
import ActivityEditScreen from './screens/admin/ActivityEditScreen';
import ClinicalSiteListScreen from './screens/admin/ClinicalSiteListScreen';
import ClinicalSiteEditScreen from './screens/admin/ClinicalSiteEditScreen';

import ClinicalDirectorListScreen from './screens/admin/ClinicalDirectorListScreen';
import ClinicalDirectorEditScreen from './screens/admin/ClinicalDirectorEditScreen';

import ClinicalSCommitmentListScreen from './screens/admin/ClinicalSCommitmentListScreen';
import ClinicalSCommitmentEditScreen from './screens/admin/ClinicalSCommitmentEditScreen';



import UserClinicalSCommitmentListScreen from './screens/UserClinicalCommitmentListScreen';
import UserClinicalSCommitmentEditScreen from './screens/UserClinicalSCommitmentEditScreen';

import SPQualListScreen from './screens/SPQualslistScreen';
import SPQualEditScreen from './screens/SPQualsEditScreen';

import RadiationSafetyListScreen from './screens/admin/RadiationSafetyListScreen';
import RadiationSafetyEditScreen from './screens/admin/RadiationSafetyEditScreen';

import UserRadiationSafetyListScreen from './screens/UserRadiationSafetyListScreen';
import UserRadiationSafetyEditScreen from './screens/UserRadiationSafetyEditScreen';

import DirectorateListScreen from './screens/admin/ADirectorateListScreen';
import DirectorateEditScreen from './screens/admin/ADirectorateEditScreen';

import LocationListScreen from './screens/admin/ALocationListScreen';
import LocationEditScreen from './screens/admin/ALocationEditScreen';

import SpecialityListScreen from './screens/admin/ASpecialityListScreen';
import SpecialityEditScreen from './screens/admin/ASpecialityEditScreen';


import ApproveWorklist from './screens/manager/UserListScreenM';
import ApprovedWorklist from './screens/manager/UsersApprovedScreenM';
import ApproveWorklistItem from './screens/manager/WorklistEditActivityScreenM';



//<Route index={true} path='/' element={<HomeScreen />} /> 

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/login' element={<LoginScreen />} />





      <Route path='/product/:id' element={<ProductScreen />} />
      <Route path='/cart' element={<CartScreen />} />
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />

      <Route path='/ResetPassword' element={<ResetScreen />} />




      {/* Registered users */}
      <Route path='' element={<PrivateRoute />}>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/search/:keyword' element={<HomeScreen />} />
        <Route path='/page/:pageNumber' element={<HomeScreen />} />
        <Route
          path='/search/:keyword/page/:pageNumber'
          element={<HomeScreen />}
        />


        <Route path='/shipping' element={<ShippingScreen />} />
        <Route path='/payment' element={<PaymentScreen />} />
        <Route path='/placeorder' element={<PlaceOrderScreen />} />
        <Route path='/order/:id' element={<OrderScreen />} />
        <Route path='/profile' element={<ProfileScreen />} />
        <Route path='/worklist' element={<WorklistScreen />} />
        <Route path='/worklist/:id' element={<WorklistEditActivityScreen />} />
        <Route path='/worklist/:id/activity/:UKey' element={<WorklistEditItemScreen />} />
        <Route path='/manager/worklist/:id/activity/:UKey/:staffID' element={<WorklistEditItemScreenM />} />
        

        <Route path='/worklist/:id/edit' element={<WorklistEditScreen />} />

        <Route path='/manager/approveWorklist' element={<ApproveWorklist />} />
        <Route path='/manager/approvedWorklist' element={<ApprovedWorklist />} />
        <Route path='/manager/worklist/:id/edit/:userID' element={<ApproveWorklistItem />} /> 
          
        
        



        <Route path='/clinicalscommitmentlist' element={<UserClinicalSCommitmentListScreen />} />
        <Route
          path='/clinicalscommitmentlist/:pageNumber'
          element={<UserClinicalSCommitmentListScreen />}
        />
        <Route path='/clinicalscommitment/:id/edit' element={<UserClinicalSCommitmentEditScreen />} />


        

        <Route path='/spqualList' element={<SPQualListScreen />} />
        <Route
          path='/spquallist/:pageNumber'
          element={<SPQualListScreen />}
        />
        <Route path='/spqualList/:id/edit' element={<SPQualEditScreen />} />





        <Route path='/radiationsafetylist' element={<UserRadiationSafetyListScreen />} />
        <Route
          path='/radiationsafetylist/:pageNumber'
          element={<UserRadiationSafetyListScreen />}
        />
        <Route path='/radiationsafety/:id/edit' element={<UserRadiationSafetyEditScreen />} />

      </Route>



      {/* ...   */}
{/* AuthMonitor will run globally across your application ,,, <AuthMonitor />  <InactivityTracker />*/}


      {/* Admin users */}
      <Route path='' element={<AdminRoute />}>
        <Route path='/admin/orderlist' element={<OrderListScreen />} />
        <Route path='/admin/productlist' element={<ProductListScreen />} />
        <Route
          path='/admin/productlist/:pageNumber'
          element={<ProductListScreen />}
        />

        <Route path='/admin/activitylist' element={<ActivityListScreen />} />
        <Route
          path='/admin/activitylist/:pageNumber'
          element={<ActivityListScreen />}
        />
        <Route path='/admin/clinicalsitelist' element={<ClinicalSiteListScreen />} />
        <Route
          path='/admin/clinicalsitelist/:pageNumber'
          element={<ClinicalSiteListScreen />}
        />

        <Route path='/admin/directoratelist' element={<DirectorateListScreen />} />
        <Route
          path='/admin/directoratelist/:pageNumber'
          element={<DirectorateListScreen />}
        />
        <Route path='/admin/locationlist' element={<LocationListScreen />} />
        <Route
          path='/admin/locationlist/:pageNumber'
          element={<LocationListScreen />}
        />
        <Route path='/admin/specialitylist' element={<SpecialityListScreen />} />
        <Route
          path='/admin/specialitylist/:pageNumber'
          element={<SpecialityListScreen />}
        />


        <Route path='/admin/clinicaldirectorlist' element={<ClinicalDirectorListScreen />} />
        <Route
          path='/admin/clinicaldirectorlist/:pageNumber'
          element={<ClinicalDirectorListScreen />}
        />


        <Route path='/admin/clinicalscommitmentlist' element={<ClinicalSCommitmentListScreen />} />
        <Route
          path='/admin/clinicalscommitmentlist/:pageNumber'
          element={<ClinicalSCommitmentListScreen />}
        />

        <Route path='/admin/radiationsafetylist' element={<RadiationSafetyListScreen />} />
        <Route
          path='/admin/radiationsafetylist/:pageNumber'
          element={<RadiationSafetyListScreen />} />

        <Route path='/admin/radiationsafety/:id/edit' element={<RadiationSafetyEditScreen />} />





        <Route path='/admin/userlist' element={<UserListScreen />} />
        <Route path='/admin/product/:id/edit' element={<ProductEditScreen />} />

        <Route path='/admin/activity/:id/edit' element={<ActivityEditScreen />} />
        <Route path='/admin/clinicalsite/:id/edit' element={<ClinicalSiteEditScreen />} />

        <Route path='/admin/directorate/:id/edit' element={<DirectorateEditScreen />} />
        <Route path='/admin/location/:id/edit' element={<LocationEditScreen />} />
        <Route path='/admin/speciality/:id/edit' element={<SpecialityEditScreen />} />

        <Route path='/admin/clinicaldirector/:id/edit' element={<ClinicalDirectorEditScreen />} />
        <Route path='/admin/clinicalscommitment/:id/edit' element={<ClinicalSCommitmentEditScreen />} />



        <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
      </Route>
    </Route>
  )
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
      
        <PayPalScriptProvider deferLoading={true}>
        
          <RouterProvider router={router}>
          
          </RouterProvider>
        </PayPalScriptProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);


reportWebVitals();
