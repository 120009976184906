// export const BASE_URL =
//   process.env.NODE_ENV === 'develeopment' ? 'http://localhost:5000' : '';
export const BASE_URL = ''; // If using proxy
export const PRODUCTS_URL = '/api/products';
export const USERS_URL = '/api/users';
export const ORDERS_URL = '/api/orders';
export const PAYPAL_URL = '/api/config/paypal';
export const UPLOAD_URL = '/api/upload';

export const ACTIVITIES_URL = '/api/activities';
export const CLINICALSITES_URL = '/api/clinicalSites';

export const CLINICALDIRECTORS_URL = '/api/clinicalDirectors';
export const CLINICALSCOMM_URL = '/api/clinicalSCommitments';
export const SPQUALS_URL= '/api/SPQuals';
export const RADIATION_URL = '/api/radiationSafety';
export const WORKLIST_URL = '/api/worklists';

export const PRIMARYNOTIFICATION_URL = '/api/primaryNotifications';

export const DIRECTORATE_URL = '/api/directorate';
export const SPECIALITY_URL = '/api/speciality';
export const LOCATION_URL = '/api/location';

export const DIRECTORATES_URL = '/api/directorates';
export const COSTCENTRES_URL = '/api/costcentres';





